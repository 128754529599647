import React from 'react';
import { object } from 'prop-types';
import Layout from '../components/Layout';
import { allComponents } from '../components';
import ProductGrid from '../components/ProductGrid';
import { withPreview } from 'gatsby-source-prismic';

const PageConstructor = ({ pageContext: { names, pageLang, newsletter }, data }) => {
  if (data && data.prismicProducts) {
    let newArr = [];
    // Preview data is loaded via proxy and we need to loop through it to get it loaded
    data.prismicProducts.data.body.forEach(element => {
      newArr.push(element);
    });
    names = newArr; // Inject it back into the main component array
  }

  const lastIndex = names.length - 1;
  const intro = names[0].__typename.slice(-5);
  const sign = names[lastIndex].__typename.slice(-5);
  const IntroModule = allComponents[intro];
  const SignPost = allComponents[sign];
  const grid = names.filter(el => el && el.__typename.slice(-5) === 'Gm002');

  return (
    <Layout pageLang={pageLang} newsletter={newsletter}>
      {intro === 'Em003' && (
        <IntroModule primary={names[0].primary} pageLang={pageLang} />
      )}

      <ProductGrid data={grid} pageLang={pageLang} />

      {sign === 'Sm004' && (
        <SignPost primary={names[lastIndex].primary} pageLang={pageLang} />
      )}
    </Layout>
  );
};

PageConstructor.propTypes = {
  pageContext: object.isRequired
};

export default withPreview(PageConstructor);

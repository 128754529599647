import React, { Component, Fragment } from 'react';
import { array } from 'prop-types';
import { ProductListingGridGm002, LoadButton, Separator } from 'components';
import { t } from '../../utils/lang-helper';

export default class ProductGrid extends Component {
  static propTypes = {
    data: array.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      toShow: 2
    };
    this.step = 2;
    this.all = props.data.reduce((acc, curr) => {
      return (
        acc +
        curr.items.length +
        (curr.primary.gm2_is_collection === 'Yes')
      );
    }, 0);
  }

  loadMore = () => {
    this.setState(prev => ({
      toShow: prev.toShow + this.step
    }));
  };

  render() {
    const { data } = this.props;
    const { toShow } = this.state;
    const cards = data.slice(0, toShow);
    const current = cards.reduce((acc, curr) => {
      return (
        acc +
        curr.items.length +
        (curr.primary.gm2_is_collection === 'Yes')
      );
    }, 0);
    const count = `showing ${current} of ${this.all}`;
    return (
      <>
        {cards.map((el, i) => (
          <Fragment key={i}>
            <ProductListingGridGm002
              primary={el.primary}
              items={el.items}
            />
            {i < cards.length - 1 && <Separator />}
          </Fragment>
        ))}
        {data.length <= toShow ? null : (
          <LoadButton
            text={t('LOAD MORE')}
            count={count}
            clickHandler={this.loadMore}
          />
        )}
      </>
    );
  }
}
